import React, { useRef, useEffect } from 'react';

const CameraComponent = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        // Hàm để mở camera
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                }
            } catch (err) {
                console.error('Error accessing the camera: ', err);
            }
        };

        startCamera();

        // Dọn dẹp khi component bị gỡ bỏ
        return () => {
            if (videoRef.current) {
                const stream = videoRef.current.srcObject;
                if (stream) {
                    const tracks = stream.getTracks();
                    tracks.forEach(track => track.stop());
                }
                videoRef.current.srcObject = null;
            }
        };
    }, []);

    return (
        <div>
            <h1>Camera</h1>
            <video ref={videoRef} width="100%" height="auto" autoPlay></video>
        </div>
    );
};

export default CameraComponent;
